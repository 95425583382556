import React, { FC } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';

import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import InnerHtml from 'components/common/DangerouslySetInnerHtml';
import Label from 'components/common/Label';

// Styles
import 'gatsby-theme-dettol/src/components/HowToUse/HowToUse.scss';
import 'gatsby-theme-dettol/src/components/SafetyInstructions/SafetyInstructions.scss';
import 'gatsby-theme-dettol/src/components/StoryTelling/StoryTelling.scss';
import 'gatsby-theme-dettol/src/components/common/CardBox/CardBox.scss';
import './ProductLandingPage.scss';

import { ProductLandingBodyRendererComponentProps } from './models';

enum ProductListingPageBlocks {
  BANNER_BLOCK = 'Banner',
  DOUBLE_TOP_BANNER_BLOCK = 'Double top banner',
  TITLE_TEXT_BLOCK = 'Title + text',
  ARTICLES_BLOCK = 'Articles banner',
  PRODUCT_BLOCK = 'Product section',
  BANNER_BG_BLOCK = 'Banner with background',
  INFO_BLOCK = 'Info section',
  FOOTER_BLOCK = 'Footer image section',
  LIGHTHOUSE_BANNER_BLOCK = 'Lighthouse Banner',
  LIGHTHOUSE_PRODUCT_BLOCK = 'Lighthouse Product section',
  LIGHTHOUSE_DOUBLE_TOP_BANNER_BLOCK = 'Lighthouse Double top banner',
}

const ProductLandingPageRender: FC<ProductLandingBodyRendererComponentProps> = ({
  breadcrumbs,
  block,
}) => {
  switch (block.structure) {
    case ProductListingPageBlocks.BANNER_BLOCK: {
      const { sectionLandmark, image, imageAlt, labelText, descriptionText } = block;

      return (
        <section className="did-you-know-block" aria-label={sectionLandmark}>
          <div className="did-you-know-block__img-holder">
            <UmbracoImage image={image} alt={imageAlt} className="did-you-know-block__img" />
          </div>
          <div className="did-you-know-block__content">
            <div className="did-you-know-block__content-col">
              <strong className="did-you-know-block__title">
                <Label>{labelText}</Label>
              </strong>
              <div className="did-you-know-block__content-holder">
                <InnerHtml html={descriptionText} className="did-you-know-block__text" />
              </div>
            </div>
          </div>
        </section>
      );
    }
    case ProductListingPageBlocks.DOUBLE_TOP_BANNER_BLOCK: {
      const {
        imageBgTopDesktop,
        imageBgTopMobile,
        imageBgTopAlt,
        topContent,
        imageBackgroundBottom,
        imageBackgroudnBottomAlt,
        bottomContent,
        imageBottomDesktop,
        imageBottomMobile,
        imageBottomAlt,
      } = block;

      return (
        <div>
          <div className="double-banner">
            <div className="double-banner__top">
              <div className="background">
                <UmbracoImage image={imageBgTopDesktop} alt={imageBgTopAlt} className="desktop" />
                <UmbracoImage image={imageBgTopMobile} alt={imageBgTopAlt} className="mobile" />
              </div>
              <InnerHtml html={topContent} className="double-banner__content-top" />
            </div>
            <div className="double-banner__bottom">
              <div className="background">
                <UmbracoImage
                  image={imageBackgroundBottom}
                  alt={imageBackgroudnBottomAlt}
                  className="desktop"
                />
              </div>
              <Row className="double-banner__content-bottom">
                <Col sm={12} md={6} className="text">
                  <InnerHtml html={bottomContent} />
                </Col>
                <Col sm={12} md={6} className="image">
                  <UmbracoImage
                    image={imageBottomDesktop}
                    alt={imageBottomAlt}
                    className="desktop"
                  />
                  <UmbracoImage image={imageBottomMobile} alt={imageBottomAlt} className="mobile" />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    }
    case ProductListingPageBlocks.TITLE_TEXT_BLOCK: {
      const { title, description } = block;

      return (
        <div className="title-text-block">
          <InnerHtml html={title} className="title-text-block__title" />
          <InnerHtml html={description} className="title-text-block__description" />
        </div>
      );
    }
    case ProductListingPageBlocks.ARTICLES_BLOCK: {
      const { customClass } = block;
      const customClassArticleBox = classNames('article-block', {
        [`${customClass}`]: customClass,
      });

      return (
        <>
          {block?.articleBox?.length ? (
            <Row className={customClassArticleBox}>
              {block.articleBox.map((box, index) => {
                const isLarge = index === 0;
                const { title, image, imageAlt, link, linkMore, text, labelText } = box.properties;
                const labelItem = labelText ? <Label type="primary">{labelText}</Label> : null;
                const classes = isLarge ? 'dt-card-box dt-card-box--large' : 'dt-card-box';
                const articleItem = (
                  <Card>
                    {labelItem}
                    <UmbracoImage image={image} alt={imageAlt} className="desktop" />
                    <Card.Body>
                      <Card.Title>{title}</Card.Title>
                      <InnerHtml html={text} className="card-text" />

                      {linkMore?.length ? (
                        <div className="article-block__cta-wrapper">
                          <Link to={linkMore[0].url} className="article-block__cta">
                            {linkMore[0].name}
                          </Link>
                        </div>
                      ) : null}
                    </Card.Body>
                  </Card>
                );

                return (
                  <Col md={6} lg={isLarge ? 8 : 4} key={title}>
                    <div className={classes}>
                      {link?.length ? <Link to={link[0].url}>{articleItem}</Link> : articleItem}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : null}
        </>
      );
    }
    case ProductListingPageBlocks.PRODUCT_BLOCK: {
      const ProductInfoGallery = loadable(() => import('components/ProductInfoGallery'));
      const ProductBuyNowDropdown = loadable(() => import('components/ProductBuyNowDropdown'));
      const BreadCrumbs = loadable(() => import('components/common/BreadCrumbs'));

      const {
        buyNowCancel,
        buyNowText,
        buyNowCustomText,
        productHeader,
        productTitle,
        productBgImage,
        productLink,
        buyNow,
        productText,
        productImage,
        productImageAlt,
        enableBreadcrumbs,
      } = block;
      const currentImage = [
        {
          childImageSharp: {
            fallbackurl: productImage.fallbackUrl,
            fluid: {
              src: productImage.fallbackUrl,
              base64: productImage.fluid.base64,
              srcSet: productImage.fluid.srcSet,
            },
          },
        },
      ];

      const customFields = {
        buttonCancel: buyNowCancel,
        buttonBuyNow: buyNowText,
        orderNowFrom: buyNowCustomText,
      };

      return (
        <div className="dt-top-info product-block">
          <Row>
            <Col sm={12} md={6} className="product-block__content">
              <InnerHtml html={productHeader} className="product-block__header" />
              {breadcrumbs?.length && Number(enableBreadcrumbs) ? (
                <BreadCrumbs data={breadcrumbs} className="product-block__breadcrumbs" />
              ) : null}
              <div className="product-block__title">{productTitle}</div>
              <ProductInfoGallery
                imgSet={currentImage}
                backGroundImage={productBgImage}
                alt={productImageAlt}
              />
              <InnerHtml html={productText} className="product-block__info" />
              {buyNow && buyNow.length ? (
                <ProductBuyNowDropdown buyNow={buyNow} customFields={customFields} />
              ) : null}
              {productLink[0].url && productLink ? (
                <Link to={productLink[0].url} className="product-block__link">
                  {productLink[0].name}
                </Link>
              ) : null}
            </Col>
          </Row>
        </div>
      );
    }
    case ProductListingPageBlocks.BANNER_BG_BLOCK: {
      const { imageBg, imageBgAlt, description, image, imageAlt, disclaimer } = block;

      return (
        <div className="banner-background">
          <UmbracoImage image={imageBg} alt={imageBgAlt} className="background" />
          <Row>
            <UmbracoImage image={imageBg} alt={imageBgAlt} className="background" />
            <Col sm={12} md={6} className="banner-background__content">
              <InnerHtml html={description} />
            </Col>
            <Col sm={12} md={6} className="banner-background__image">
              <UmbracoImage image={image} alt={imageAlt} />
            </Col>
            <InnerHtml html={disclaimer} className="banner-background__disclaimer-text" />
          </Row>
        </div>
      );
    }
    case ProductListingPageBlocks.INFO_BLOCK: {
      const {
        titleInfo,
        descriptionInfo,
        linkInfo,
        imageInfoDesktop,
        imageInfoDesktopAlt,
        imageInfoMobile,
        imageInfoAltMobile,
      } = block;

      return (
        <div className="info-section">
          <Row>
            <Col sm={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              <Row className="info-section__wrapper">
                <Col sm={12} md={6} className="info-section__content">
                  <div className="info-section__image-mobile">
                    <UmbracoImage
                      className="info-section__image-mobile"
                      image={imageInfoMobile}
                      alt={imageInfoAltMobile}
                    />
                  </div>

                  <InnerHtml className="info-section__title" html={titleInfo} />
                  <InnerHtml className="info-section__description" html={descriptionInfo} />

                  {linkInfo[0].url && linkInfo ? (
                    <div className="info-section__cta-wrapper">
                      <Link to={linkInfo[0].url} className="info-section__cta">
                        {linkInfo[0].name}
                      </Link>
                    </div>
                  ) : null}
                </Col>
                <Col sm={12} md={6}>
                  <div className="info-section__image">
                    <UmbracoImage image={imageInfoDesktop} alt={imageInfoDesktopAlt} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
    case ProductListingPageBlocks.FOOTER_BLOCK: {
      const { imageFooterBg, imageFooterBgAlt } = block;

      return (
        <div className="footer-background">
          <UmbracoImage image={imageFooterBg} alt={imageFooterBgAlt} className="background" />
        </div>
      );
    }
    case ProductListingPageBlocks.LIGHTHOUSE_BANNER_BLOCK: {
      const {
        imageLighthouse,
        imageAltLighthouse,
        labelTextLighthouse,
        descriptionTextLighthouse,
        linkLighthouse,
      } = block;

      return (
        <div className="lighthouse-banner">
          <div className="lighthouse-banner__wrapper">
            <div className="lighthouse-banner__image">
              <UmbracoImage
                image={imageLighthouse}
                alt={imageAltLighthouse}
                className="did-you-know-block__img"
              />
            </div>
            <div className="lighthouse-banner__content">
              <div className="">
                <strong className="lighthouse-banner__label">
                  <Label>{labelTextLighthouse}</Label>
                </strong>
                <div className="lighthouse-banner__content-holder">
                  <InnerHtml html={descriptionTextLighthouse} className="" />

                  {linkLighthouse[0].url && linkLighthouse ? (
                    <div className="lighthouse-banner__cta-wrapper">
                      <Link to={linkLighthouse[0].url} className="lighthouse-banner__cta">
                        {linkLighthouse[0].name}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    case ProductListingPageBlocks.LIGHTHOUSE_PRODUCT_BLOCK: {
      const ProductInfoGallery = loadable(() => import('components/ProductInfoGallery'));
      const ProductBuyNowDropdown = loadable(() => import('components/ProductBuyNowDropdown'));
      const BreadCrumbs = loadable(() => import('components/common/BreadCrumbs'));

      const {
        buyNowCancel,
        buyNowText,
        buyNowCustomText,
        productHeader,
        productTitle,
        productBgImage,
        productLink,
        buyNow,
        productText,
        productImage,
        productImageAlt,
        enableBreadcrumbs,
        label,
        disclaimers,
      } = block;
      const currentImage = [
        {
          childImageSharp: {
            fallbackurl: productImage.fallbackUrl,
            fluid: {
              src: productImage.fallbackUrl,
              base64: productImage.fluid.base64,
              srcSet: productImage.fluid.srcSet,
            },
          },
        },
      ];

      const customFields = {
        buttonCancel: buyNowCancel,
        buttonBuyNow: buyNowText,
        orderNowFrom: buyNowCustomText,
      };

      return (
        <div className="dt-top-info product-block product-block__wide">
          <div className="product-block__inner">
            {label && label.length ? (
              <strong className="lighthouse-banner__label">
                <Label>{label}</Label>
              </strong>
            ) : null}

            <Row>
              <Col sm={12} md={7} className="product-block__content">
                {productHeader && productHeader.length ? (
                  <InnerHtml html={productHeader} className="product-block__header" />
                ) : null}
                {breadcrumbs?.length && Number(enableBreadcrumbs) ? (
                  <BreadCrumbs data={breadcrumbs} className="product-block__breadcrumbs" />
                ) : null}
                <div className="product-block__title">{productTitle}</div>
                <InnerHtml html={productText} className="product-block__info" />
                {buyNow && buyNow.length ? (
                  <ProductBuyNowDropdown buyNow={buyNow} customFields={customFields} />
                ) : null}
                {productLink[0].url && productLink ? (
                  <Link to={productLink[0].url} className="product-block__link">
                    {productLink[0].name}
                  </Link>
                ) : null}
                <ProductInfoGallery
                  imgSet={currentImage}
                  backGroundImage={productBgImage}
                  alt={productImageAlt}
                />
                {disclaimers && disclaimers.length ? (
                  <InnerHtml html={disclaimers} className="product-block__disclaimers" />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    case ProductListingPageBlocks.LIGHTHOUSE_DOUBLE_TOP_BANNER_BLOCK: {
      const {
        imageBgTopDesktop,
        imageBgTopMobile,
        imageBgTopAlt,
        topContent,
        imageBackgroundBottom,
        imageBackgroudnBottomAlt,
        bottomContent,
        imageBottomDesktop,
        imageBottomMobile,
        imageBottomAlt,
      } = block;

      return (
        <div>
          <div className="double-banner double-banner__wide">
            <div className="double-banner__top">
              <div className="background">
                <UmbracoImage image={imageBgTopDesktop} alt={imageBgTopAlt} className="desktop" />
                <UmbracoImage image={imageBgTopMobile} alt={imageBgTopAlt} className="mobile" />
              </div>
              <InnerHtml html={topContent} className="double-banner__content-top" />
            </div>
            <div className="double-banner__bottom">
              <div className="background">
                <UmbracoImage
                  image={imageBackgroundBottom}
                  alt={imageBackgroudnBottomAlt}
                  className="desktop"
                />
              </div>
              <Row className="double-banner__content-bottom">
                <Col sm={12} md={7} className="text">
                  <InnerHtml html={bottomContent} />
                </Col>
                <Col sm={12} md={5} className="image">
                  <UmbracoImage
                    image={imageBottomDesktop}
                    alt={imageBottomAlt}
                    className="desktop"
                  />
                  <UmbracoImage image={imageBottomMobile} alt={imageBottomAlt} className="mobile" />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    }

    default:
      return null;
  }
};

export default ProductLandingPageRender;
