import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { TProductLandingPageProps } from './models';
import Layout from '../../components/Layout';
import ProductLandingPageRender from './ProductLandingPageRender';

import 'styles/main.scss';

const ProductLandingPage: FC<TProductLandingPageProps> = ({
  pageContext: { breadCrumbs },
  data: {
    footerNavigation,
    homepageSettings,
    languageSelector,
    siteSettings,
    mobileAppPromoBanner,
    menu,
    productLandingPage,
  },
}) => {
  const { bodyBlock, seoMetaTitle, seoMetaKeywords, seoMetaDescription, link } = productLandingPage;

  return (
    <Layout
      headerTransparent={false}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      {bodyBlock.length ? (
        <section className="double-banner-container">
          {bodyBlock.map((block) => (
            <ProductLandingPageRender
              breadcrumbs={breadCrumbs}
              key={block.structure}
              block={{ ...block }}
            />
          ))}
        </section>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query productlandingPageQuery($link: String!, $lang: String) {
    productLandingPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      bodyBlock {
        actionLabelText
        articleSectionDescription
        blockType
        boldText
        description
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        labelText
        imageAlt
        productFeatures {
          boldText
          regularText
          properties {
            description
            iconName
          }
        }
        regularText
        structure
        videoUrl
        buttonText
        buttonURL {
          url
        }
        ariaLabel
        didYouKnowBG {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
        sectionLandmark
        imageBgTopDesktop {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageBgTopMobile {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageBgTopAlt
        topContent
        imageBackgroundBottom {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageBackgroundBottomAlt
        bottomContent
        imageBottomDesktop {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageBottomMobile {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageBottomAlt
        labelText
        descriptionText
        title
        customClass
        articleBox {
          properties {
            title
            link {
              url
            }
            linkMore {
              url
              name
            }
            image {
              fallbackUrl
              fluid {
                srcSet
                base64
              }
            }
            text
            labelText
          }
        }
        productTitle
        productHeader
        productText
        productImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        productImageAlt
        productBgImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        buyNow {
          properties {
            link {
              url
              name
              icon
              published
            }
            shopName
            discountContent
          }
        }
        buyNowText
        buyNowCustomText
        buyNowCancel
        productLink {
          url
          name
        }
        imageBg {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        disclaimer
        imageBgAlt
        enableBreadcrumbs
        titleInfo
        descriptionInfo
        linkInfo {
          url
          name
        }
        imageInfoDesktop {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageInfoDesktopAlt
        imageInfoMobile {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageInfoAltMobile
        imageFooterBg {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageFooterBgAlt
        labelTextLighthouse
        descriptionTextLighthouse
        linkLighthouse {
          url
          name
        }
        imageLighthouse {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAltLighthouse
        label
        disclaimers
      }
    }
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
  }
`;

export default ProductLandingPage;
